import React from "react"
import { graphql } from "gatsby"
import moment from "moment"
import { hyphenated } from "hyphenated"
import de from "hyphenated-de"

import Seo from "../components/seo"
import BackButton from "../components/backButton"
import Localize from "../components/localize"

import BlockContent from "@sanity/block-content-to-react"

const PublicationsDetail = ({
  data: { publications, site },
  pageContext,
  location,
}) => {
  const lan = pageContext.locale

  const serializers = {
    types: {
      span: props => {
        return <p>{hyphenated(props.node)}</p>
      },
      block: props => {
        const { style = "normal" } = props.node
        if (/^h\d/.test(style)) {
          const level = style.replace(/[^\d]/g, "")
          return React.createElement(
            style,
            { className: `heading-${level}` },
            props.children
          )
        }
        if (style === "blockquote") {
          return (
            <blockquote>
              - {hyphenated(props.children, { language: de })}
            </blockquote>
          )
        }

        if (style === "normal") {
          return (
            <p>
              {lan === "en"
                ? hyphenated(props.children[0])
                : hyphenated(props.children[0], { language: de })}
            </p>
          )
        }
        // Fall back to default handling
        // return BlockContent.defaultSerializers.types.block(props)
      },
      code: props => (
        <pre data-language={props.node.language}>
          <code>{hyphenated(props.node.code)}</code>
        </pre>
      ),
    },
    list: props =>
      props.type === "bullet" ? (
        <ul>{props.children}</ul>
      ) : (
        <ol>{props.children}</ol>
      ),
    listItem: props => {
      return props.type === "bullet" ? (
        <li>
          {lan === "en"
            ? hyphenated(props.children[0])
            : hyphenated(props.children[0], { language: de })}
        </li>
      ) : (
        <li>
          {lan === "en"
            ? hyphenated(props.children[0])
            : hyphenated(props.children[0], { language: de })}
        </li>
      )
    },
    marks: {
      strong: props => <strong>{props.children}</strong>,
      em: props => <em>{props.children}</em>,
      code: props => <code>{props.children}</code>,
    },
    mainImage: props => {
      const url = props.node.asset._ref.split("-")

      return (
        <div
          className="profileimage p-0"
          style={{
            margin: "0 auto",
          }}
        >
          <img
            className="object-fit-contain br-20"
            style={{
              maxWidth: "500px",
              maxHeight: "500px",
            }}
            height={"100%"}
            width={"100%"}
            src={`https://cdn.sanity.io/images/tvjp7ojk/production/${
              url[1] + "-" + url[2] + "." + url[3]
            }`}
            alt="img"
          />
        </div>
      )
    },
    // <pre data-language={props.node.language}>
    //   <code>{props.node.code}</code>
    // </pre>
  }

  return (
    <>
      <Seo
        lan={pageContext.locale}
        title={publications.headline}
        description={publications.subDescription}
        image={
          publications.image
            ? publications.image.asset && publications.image.asset.url
            : null
        }
        url={`${site.siteMetadata.siteUrl}/${pageContext.locale}/${pageContext.url}`}
        typ="article"
        titleTemplate={`${publications.headline} - Haackschubert`}
      />
      <div
        id="team"
        className="team-home-wrapper mt--100 career-button no--margin-menu padding--huge-menu-sw"
      >
        <BackButton
          lan={pageContext.locale}
          state={location.state}
          to={`/${pageContext.locale}/publications/`}
          text={pageContext.locale === "en" ? "Publications" : "Publikationen"}
          style={{ right: "calc(50% - 670px)" }}
        />
        <div
          className="profileimage p-0"
          style={{
            maxWidth: "500px",
            maxHeight: "500px",
            marginLeft: "auto",
          }}
        >
          {publications.image
            ? publications.image.asset.url && (
                <picture>
                  <source
                    media="(max-width: 540px)"
                    srcSet={`${publications.image.asset.url}?h=300`}
                  />
                  <source
                    media="(max-width: 1024px)"
                    srcSet={`${publications.image.asset.url}?h=400`}
                  />
                  <img
                    src={
                      publications.image.asset &&
                      `${publications.image.asset.url}?h=600`
                    }
                    alt="img"
                    loading="lazy"
                    width="100%"
                    height="100%"
                    style={{
                      maxWidth: "500px",
                      maxHeight: "500px",
                    }}
                    className="br-20 object-fit-cover"
                  />
                </picture>
              )
            : null}
        </div>
        <div className="row d-block">
          <div className="col col-1 mt--30">
            <h1 className="pub-head">{hyphenated(publications.headline)}</h1>
            <span>
              Offenbach am Main{" "}
              <span>
                •{" "}
                {moment(publications.publishedAt)
                  .local()
                  .format("YYYY MMMM, DD")}
              </span>
            </span>
          </div>
          {publications.description && (
            <div className="col col-1">
              <div className="simpletext padding p-0">
                <BlockContent
                  serializers={serializers}
                  imageOptions={{
                    width: 500,
                    height: 500,
                    fit: "max",
                  }}
                  blocks={
                    pageContext.locale === "en"
                      ? publications.description._rawEn
                      : publications.description._rawDe
                  }
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export const query = graphql`
  query($slug: String!, $locale: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    publications: sanityPublications(slug: { current: { eq: $slug } }) {
      headline {
        _type
        en
        de
      }
      image {
        asset {
          url
        }
      }
      publishedAt(locale: $locale)
      subDescription {
        _type
        en
        de
      }
      description {
        _rawDe
        _rawEn
      }
    }
  }
`

export default Localize(PublicationsDetail)
